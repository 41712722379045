import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next' //语言包

import { Popconfirm, Tooltip, Button, Divider, Typography, Space, Checkbox } from 'antd'
import { ExclamationCircleOutlined, FormOutlined, HistoryOutlined, SettingOutlined, SaveOutlined, CopyOutlined, ArrowUpOutlined, ArrowRightOutlined, PlusOutlined, SwapOutlined, ChartOutlined, BarChartOutlined } from '@ant-design/icons'
import IconSvg from '../IconSvg'
import { BackToTopIconWrapper, SwitchIconWrapper, DelectIconWrapper, EditIconWrapper, ResetIconWrapper, TransformIconWrapper, SetIconWrapper, ChartIconWrapper, FoldIconWrapper, CheckAllWrapper, SyncWrapper, SaveIconWrapper, DetailWrapper, CopyIconWrapper, LinkTextWrapper, BlockWrapper, UnblockWrapper, ListWrapper, AddWrapper, EditWrapper } from './style'

import { MiniWarning } from '../mininote'
import { props } from 'ramda'

/* 返回顶部 Icon */
const BackToTopIconOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <BackToTopIconWrapper onClick={props.onClick} style={props.style}>
        <Tooltip placement='top' title={t('All.Link.goToTop')}>
          <Button type='primary' shape='circle' icon={<ArrowUpOutlined />} onClick={props.onClick} />
        </Tooltip>
      </BackToTopIconWrapper>
    </>
  )
}

/* 切换 Icon */
const SwitchIconOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <SwitchIconWrapper>
        <Tooltip placement='top' title={t('All.Operation.switch')}>
          <Button shape='circle' icon={<IconSvg icon='fal fa-angle-right' />} onClick={props.onClick} />
        </Tooltip>
      </SwitchIconWrapper>
    </>
  )
}

/* 删除 Icon */
const DelectIconOperation = (props) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (props.isShow !== undefined && props.isShow !== '') {
      setIsShow(props.isShow)
    } else {
      setIsShow(true)
    }
  }, [props.isShow])
  useEffect(() => {
    if (props.disabled !== undefined && props.disabled !== '') {
      setIsDisabled(props.disabled)
    } else {
      setIsDisabled(false)
    }
  }, [props.disabled])

  if (props.isPop === undefined || props.isPop) {
    return (
      <>
        <DelectIconWrapper className={isShow ? 'Show' : 'isHide'} className={props.type}>
          <Popconfirm
            placement='left'
            title={<MiniWarning text={t('All.Popconfirm.Reconfirm.title-confirm')} />}
            cancelText={t('All.Operation.cancel')}
            okText={t('All.Operation.submit')}
            okType='danger'
            disabled={props.disabled || false}
            onConfirm={props.delectClick}
            icon={false}
          >
            <Tooltip placement='top' title={props.type === 'asIcon' ? '' : t('All.Operation.delect')}>
              <Button disabled={isDisabled} {...props}><IconSvg icon='fal fa-trash-alt' /></Button>
            </Tooltip>
          </Popconfirm>
        </DelectIconWrapper>
      </>
    )
  } else {
    return (
      <>
        <DelectIconWrapper className={isShow ? 'Show' : 'isHide'}>
          <Button onClick={props.delectClick}><IconSvg icon='fal fa-trash-alt' /></Button>
        </DelectIconWrapper>
      </>
    )
  }
}

/* 修改 Icon */
const EditIconOperation = (props) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (props.isShow !== undefined && props.isShow !== '') {
      setIsShow(props.isShow)
    } else {
      setIsShow(true)
    }
  }, [props.isShow])
  useEffect(() => {
    if (props.isDisabled !== undefined && props.isDisabled !== '') {
      setIsDisabled(props.isDisabled)
    } else {
      setIsDisabled(false)
    }
  }, [props.isDisabled])
  return (
    <>
      <EditIconWrapper className={isShow ? 'Show' : 'isHide'} className={props.type} className={props.iconSize} >
        <Tooltip placement='top' title={props.type === 'asIcon' ? '' : t('All.Operation.edit')}>
          <Button disabled={isDisabled} onClick={props.editClick} {...props}><IconSvg icon='fal fa-pencil' /></Button>
        </Tooltip>
      </EditIconWrapper>
    </>
  )
}

/* 重置 Icon */
const ResetIconOperation = (props) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (props.isShow !== undefined && props.isShow !== '') {
      setIsShow(props.isShow)
    } else {
      setIsShow(true)
    }
  }, [props.isShow])
  useEffect(() => {
    if (props.isDisabled !== undefined && props.isDisabled !== '') {
      setIsDisabled(props.isDisabled)
    } else {
      setIsDisabled(false)
    }
  }, [props.isDisabled])

  if (props.confirm || props.confirm === undefined) {
    return (
      <>
        <ResetIconWrapper className={` ${isShow ? 'Show' : 'isHide'} ${props.type}`} >
          <Popconfirm
            placement='left'
            title={<MiniWarning text={t('All.Popconfirm.Reconfirm.title-confirm')} />}
            cancelText={t('All.Operation.cancel')}
            okText={t('All.Operation.submit')}
            okType='danger'
            disabled={props.disabled || false}
            onConfirm={props.reductClick}
            icon={false}
          >
            <Tooltip placement='top' title={props.type === 'asIcon' ? '' : t('All.Operation.reset')}>
              <Button disabled={isDisabled} size={props.size || 'small'} shape='circle' icon={<HistoryOutlined />} {...props} />
            </Tooltip>
          </Popconfirm>
        </ResetIconWrapper>
      </>
    )
  } else {
    return (
      <>
        <ResetIconWrapper className={` ${isShow ? 'Show' : 'isHide'} ${props.type}`}>
          <Tooltip placement='top' title={props.type === 'asIcon' ? '' : t('All.Operation.reset')}>
            <Button disabled={isDisabled} size={props.size || 'small'} shape='circle' icon={<HistoryOutlined />} onClick={props.reductClick} {...props} />
          </Tooltip>
        </ResetIconWrapper>
      </>
    )
  }
}

/* 转让 Icon */
const TransformIconOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <TransformIconWrapper>
        <Tooltip placement='top' title={t('All.Operation.transform')}>
          <Button onClick={props.transferenceClick}><IconSvg icon='fal fa-house-user' /></Button>
        </Tooltip>
      </TransformIconWrapper>
    </>
  )
}

/* 同步 */
const SyncOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <SyncWrapper className={props.type}>
        <Tooltip placement='top' title={props.toolText || ''}>
          <Button size='default' shape='round' type='primary' icon={<SwapOutlined />}>{t('All.Operation.sync')}</Button>
        </Tooltip>
      </SyncWrapper>
    </>
  )
}

/* 设置 */
const SetIconOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <SetIconWrapper>
        <Tooltip placement='top' title={t('All.Operation.set')}>
          <Button size={props.size || 'small'} shape='circle' type='primary' icon={<SettingOutlined />} onClick={props.setClick} />
        </Tooltip>
      </SetIconWrapper>
    </>
  )
}

/* 保存 */
const SaveIconOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <SaveIconWrapper className={props.type}>
        <Tooltip placement='top' title={t('All.Tooltip.save')}>
          <Button size='small' shape='circle' disabled={props.disabled || false} type='primary' icon={<SaveOutlined />} onClick={props.saveClick} />
        </Tooltip>
      </SaveIconWrapper>
    </>
  )
}

/* 折叠 */
const FoldIconOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <FoldIconWrapper className={props.isShowExpand ? 'isShow' : 'isHide'}>
        <Button
          type='default'
          shape='round'
          icon={props.icon}
          onClick={props.onClick}

        >
          <Typography.Text>{props.text}</Typography.Text>
        </Button>
      </FoldIconWrapper>
    </>
  )
}

/* 查看图表 */
const ChartIconOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <ChartIconWrapper>
        <Tooltip placement='top' title={t('All.Tooltip.detail')}>
          <Button icon={<BarChartOutlined />} {...props}></Button>
        </Tooltip>
      </ChartIconWrapper>
    </>
  )
}

/* 详情 */
const DetailOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <DetailWrapper>
        <Button type='primary' shape='round' onClick={props.detailClick} >{props.text || t('All.Operation.detail')}</Button>
      </DetailWrapper>
    </>
  )
}

/* 复制 */
const CopyOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <CopyIconWrapper>
        <Typography.Text
          title={props.carrierContent}
          copyable={{
            tooltips: [t('All.Tooltip.copy'), t('All.Tooltip.copied')],
            text: props.carrierContent
          }}
        >
          <span className='limitingWidth maxWidth264'>
            {props.carrierContent}
          </span>
        </Typography.Text>
      </CopyIconWrapper>
    </>
  )
}

/* 外链文本 */
const LinkTextOperation = (props) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);
  useEffect(() => {
    if (props.isShow !== undefined && props.isShow !== '') {
      setIsShow(props.isShow)
    } else {
      setIsShow(true)
    }
  }, [props.isShow])
  return (
    <>
      <LinkTextWrapper>
        <Tooltip placement='top' title={props.tooltipText} className={isShow ? 'isShow' : 'isHide'}>
          <Button type='link' {...props}>
            {props.linkText}
          </Button>
        </Tooltip>
      </LinkTextWrapper>
    </>
  )
}

/* 屏蔽 */
const BlockOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <BlockWrapper>
        <Popconfirm
          disabled={props.isDis || false}
          placement='left'
          title={<MiniWarning text={t('All.Popconfirm.Reconfirm.title-confirm')} />}
          cancelText={t('All.Operation.cancel')}
          okText={t('All.Operation.submit')}
          okType='danger'
          icon={false}
          onConfirm={props.onConfirm}
        >
          <Button disabled={props.isDis || false}>{t('All.Operation.block')}</Button>
        </Popconfirm>
      </BlockWrapper>
    </>
  )
}

/* 取消屏蔽 */
const UnblockOperation = (props) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (props.isShow !== undefined && props.isShow !== '') {
      setIsShow(props.isShow)
    } else {
      setIsShow(true)
    }
  }, [props.isShow])
  useEffect(() => {
    if (props.isDisabled !== undefined && props.isDisabled !== '') {
      setIsDisabled(props.isDisabled)
    } else {
      setIsDisabled(false)
    }
  }, [props.isDisabled])
  return (
    <>
      <UnblockWrapper className={isShow ? 'Show' : 'Hide ClearWidth'}>
        <Popconfirm
          disabled={props.isDis || false}
          placement='left'
          title={<MiniWarning text={t('All.Popconfirm.Reconfirm.title-confirm')} />}
          cancelText={t('All.Operation.cancel')}
          okText={t('All.Operation.submit')}
          okType='danger'
          icon={false}
          onConfirm={props.onConfirm}
        >
          <Button disabled={props.isDis || false}>{t('All.Operation.unblock')}</Button>
        </Popconfirm>
      </UnblockWrapper>
    </>
  )
}

/* 列表弹框操作按钮 */
const ListOperation = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <ListWrapper>
        <Tooltip placement='top' title={props.tooltipText || ''}>
          <Button type='primary' {...props} >
            {props.btnText}
          </Button>
        </Tooltip>
      </ListWrapper>
    </>
  )
}

/* 新增 */
const AddOperation = (props) => {
  const { t } = useTranslation();
  const [tooltipText, setTooltipText] = useState('');
  useEffect(() => {
    if (props.tooltipType === 'tooltipWarning' && props.tooltipText !== '') {
      setTooltipText(<>
        <Space>
          <ExclamationCircleOutlined />
          <Typography.Text>{props.tooltipText}</Typography.Text>
        </Space>
      </>)
    } else {
      setTooltipText(props.tooltipText)
    }
  }, [props.tooltipType, props.tooltipText])

  return (
    <>
      <AddWrapper className={props.type}>
        <Tooltip
          placement='top'
          title={tooltipText || ''}
          overlayClassName={props.tooltipType || ''}
        >
          <Button type='dashed' icon={<PlusOutlined />} {...props}>
            {props.btnText}
          </Button>
        </Tooltip>
      </AddWrapper>
    </>
  )
}

/* 修改 */
const EditOperation = (props) => {
  const { t } = useTranslation();
  const [tooltipText, setTooltipText] = useState('');
  useEffect(() => {
    if (props.tooltipType === 'tooltipWarning' && props.tooltipText !== '') {
      setTooltipText(<>
        <Space>
          <ExclamationCircleOutlined />
          <Typography.Text>{props.tooltipText}</Typography.Text>
        </Space>
      </>)
    } else {
      setTooltipText(props.tooltipText)
    }
  }, [props.tooltipType, props.tooltipText])

  return (
    <>
      <EditWrapper  className={props.type}>
        <Tooltip
          placement='top'
          title={tooltipText || ''}
          overlayClassName={props.tooltipType || ''}
        >
          <Button type='dashed'   icon={<FormOutlined />} {...props}>
            {props.btnText}
          </Button>
        </Tooltip>
      </EditWrapper>
    </>
  )
}

export { ListOperation, AddOperation, EditOperation, BackToTopIconOperation, SwitchIconOperation, DelectIconOperation, EditIconOperation, ResetIconOperation, TransformIconOperation, ChartIconOperation, SetIconOperation, SaveIconOperation, SyncOperation, DetailOperation, FoldIconOperation, CopyOperation, LinkTextOperation, BlockOperation, UnblockOperation }