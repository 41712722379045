import { combineReducers } from 'redux-immutable';
import {reducre as addDeviceInfoReducer} from '../components/addDeviceInfo/store'
import {reducre as updateDeviceInfoReducer} from '../components/editDeviceInfo/store'
import {reducre as deviceListReducer} from '../components/deviceList/store'
import {reducre as pmuListReducer} from '../components/collectorList/store'
const defaultCombine = combineReducers({
  addDeviceInfo:addDeviceInfoReducer,
  updateDeviceInfo:updateDeviceInfoReducer,
  deviceList:deviceListReducer,
  pmuListManage:pmuListReducer
})
export default defaultCombine