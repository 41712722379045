import service from '../request';

//用户名格式验证
export const valiUserCode = (rule, value, callback) => {
  var flag = /^[A-Za-z0-9]+$/.test(value);
  if (flag)
    callback();
  else {
    callback(flag);
  }
}

//用户名格式验证
export const valiIsRepeatUserCode = (rule, value, callback) => {
  if (value !== '' && value !== undefined) {
    service.get('service-user/user/userCodeIsExist?userCode=' + value, {}).then(function (data) {
      var ret = data
      if (ret.success)//已重复
        callback();
      else
        callback(false);
    })
  } else {
    callback();
  }
}

//用户名格式验证
export const valiIsUserCode = (rule, value, callback) => {
  if (value !== '' && value !== undefined) {
    service.get('service-user/user/userCodeIsExist?userCode=' + value, {}).then(function (data) {
      var ret = data
      if (ret.success)//已重复
        callback(false);
      else
        callback();
    })
  } else {
    callback();
  }
}



//特殊字符验证
export const valiCharacters = (rule, value, callback) => {
  var flag = /[<>%$&*#]/.test(value);
  if (!flag)
    callback();
  else {
    callback(flag);
  }
}

//电子邮箱格式验证
export const valiUserEmail = (rule, value, callback) => {
  var flag = /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(value);
  if (flag)
    callback();
  else {
    callback(flag);
  }
}

//电子邮箱是否重复(免token,非注册使用)
export const valiIsRepeatEmail = (rule, value, callback) => {
  try {
    if (value !== '' && value !== undefined) {
      var userId = window.sessionStorage.getItem("userId")
      //updateUserIsEmailExist
      service.get('service-user/user/updateUserIsEmailExist?userId=' + userId + '&userEmail=' + value, {}).then(function (data) {
        var ret = data
        if (ret.success)//已重复
          callback();
        else
          callback(false);
      })
    } else {
      callback();
    }
  } catch (e) {

  }
}

//电子邮箱是否重复(注册使用)
export const valiIsRegRepeatEmail = (rule, value, callback) => {
  try {
    if (value !== '' && value !== undefined) {
      //var userId = window.sessionStorage.getItem("userId")
      //updateUserIsEmailExist
      service.get('service-user/user/userEmailIsExist?userEmail=' + value, {}).then(function (data) {
        var ret = data
        if (ret.success)//已重复
          callback();
        else
          callback(false);
      })
    } else {
      callback();
    }
  } catch (e) {

  }
}

//密码最小长度验证
export const valiUCMin = (rule, value, callback) => {
  if (value.length < 6)
    callback(false);
  else
    callback();
}

//密码最大长度验证
export const valiUCMax = (rule, value, callback) => {
  if (value.length > 25)
    callback(false);
  else
    callback();
}

//密码最小长度验证
export const valiPWMin = (rule, value, callback) => {
  if (value.length < 6)
    callback(false);
  else
    callback();
}

//密码最大长度验证
export const valiPWMax = (rule, value, callback) => {
  if (value.length > 25)
    callback(false);
  else
    callback();
}

//密码验证
export const valiPassword = (rule, value, callback) => {
  var flag = /^(?![a-z]+$)(?![0-9]+$)[a-zA-Z0-9]{0,50}$/.test(value);
  if (flag)
    callback();
  else {
    callback(flag);
  }
}

//电话验证
export const valiTelephone = (rule, value, callback) => {
  var flag = /^[0-9 \-#*+()]*$/.test(value);
  if (flag)
    return Promise.resolve();
  else {
    return Promise.reject(false)
  }
}


//采集器序列号验证
export const valiPmuSn = (rule, value, callback) => {
  if (value.length === 10 || value.length === 15 || value.length === 12) {
    return Promise.resolve();
  }
  else {
    return Promise.reject(false)
  }
}




//电站名称验证
export const valiIsPsName = (rule, value, callback) => {
  if (value !== '' && value !== undefined) {
    service.get('service-psinfo/psinfo/getPsNameIsExist?psId=&psName=' + value, {}).then(function (data) {
      var ret = data
      if (parseInt(ret.status) === 623)//已重复
        callback(false);
      else
        callback();
    })
  } else {
    callback(false);
  }
}



export const valiIsPmuSn = (rule, value, callback) => {
  if (value !== '' && value !== undefined) {
    service.get('service-psinfo/pmusn/pmuSnIsExist?sn=' + value, {}).then(function (data) {
      var ret = data
      if (parseInt(ret.status) === 655)//采集器号不存在
        callback(false);
      else
        callback();
    })
  } else {
    callback(false);
  }
}

export const valiIsUsePmuSn = (rule, value, callback) => {
  if (value !== '' && value !== undefined) {
    service.get('service-psinfo/pmu/isUseExistPmu?pmuSn=' + value, {}).then(function (data) {
      var ret = data
      if (parseInt(ret.status) === 656)//采集器号已使用
        callback(false);
      else
        callback();
    })
  } else {
    callback();
  }
}


//数字
export const valiIsNum = (rule, value, callback) => {
  var flag = /^[0-9]*$/.test(value);
  if (flag)
    callback();
  else {
    callback(flag);
  }
}
