import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next' //语言包
import { connect } from 'react-redux'

import { Form, Input, Divider, Typography, Modal, Button, Space, Row, Col } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { RegisterWrapper, AddPsBtn } from './style'

import { AddOperation } from '../../../../common/operation'
import { SuccessIcon } from '../../../../common/resulticon'

import { addUserPsRole, isExistPmuOrPs } from '../../../../request/api'
import AddPsInfo from '../psInfo/index'
import moment from 'moment'
import service from '../../../../request'
import Tour from '../../../tour/index'

const RegisterPsInfo = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    handleOpen
  }))

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [okTextStr, setOkTextStr] = useState(t('All.Operation.submit'));
  const [registerPsForm] = Form.useForm();
  const isErrState = useRef(0);
  const [textInfo, setTextInfo] = useState({
    paramsOne: '',
    paramsTwo: '',
    paramsThree: ''
  });
  const typeButton = useRef('');
  const [loading, setLoading] = useState(false);
  const [disabling, setDisabling] = useState(true)
  const [nextNum, setNextNum] = useState(-1);
  const [sunLoad, setSunLoad] = useState(true);
  const [subPsInfo, setSubPsInfo] = useState(0);
  const [pmuSn, setPmuSn] = useState('');

  const [status, setStatus] = useState(false);

  useEffect(() => {
    //openRegister();
    // document.body.style.overflow = 'hidden'

    if (props.psList.length === 0) {
      start();
    }
  }, [props.psList]);


  const start = () => {
    setStatus(true);
  };

  const onSetStatus = (data: boolean) => {
    setStatus(data);
  };

  //点击引导添加弹出绑定电站功能
  const handleBackAction = (value) => {
    switch (value) {
      case 'next':
        break;
      case 'skip':
        showModal()
        break;
      default:
        break;
    }
  }

  //上一步
  const handleBack = () => {
    setNextNum(0)
    setSunLoad(false)
    setOkTextStr(t('All.Operation.next'))
  }

  const handleCancel = () => {
    setIsOpen(false);
    setNextNum(0)
    registerPsForm.resetFields();
    setDisabling(false);
    setTextInfo({
      paramsOne: '',
      paramsTwo: '',
      paramsThree: ''
    });
    setSunLoad(true);
    document.body.classList.remove('bodyCss');
  };

  const showModal = () => {
    isErrState.current = 0
    setIsOpen(true);
    setDisabling(true);
    setOkTextStr(t('All.Operation.submit'))
    setIsOpen(true);
    document.body.classList.add('bodyCss');
  }

  const handleOpen = () => {
    isErrState.current = 0
    setIsOpen(true);
    setDisabling(true);
    setOkTextStr(t('All.Operation.submit'))
    setIsOpen(true);
  }

  const handlePsVail = (value) => {
    if (!value) {
      setLoading(false)
    }
  }

  const handleIsExistPmuOrPs = () => {
    setOkTextStr(t('All.Operation.submit'))
    registerPsForm.validateFields().then(values => {
      setDisabling(false)
      var params = { psIdSn: '', userId: '' }
      params['psIdSn'] = values.registerPsForm['psIdSn'];
      params['userId'] = props.userInfo['userId']
      isExistPmuOrPs(params['psIdSn']).then((res) => {
        if (res.status === 200) {
          isErrState.current = 200
          typeButton.current = res.data.type;
          if (res.data.type !== undefined && res.data.type === 'pmuId') {
            setTextInfo({
              paramsOne: res.data.modelName,
              paramsTwo: res.data.brandName,
              paramsThree: res.data.payNum
            });
            setOkTextStr(t('All.Operation.next'))
          }
          if (res.data.type !== undefined && res.data.type === 'psId') {
            setTextInfo({
              paramsOne: res.data.psName,
              paramsTwo: res.data.psTypeName,
              paramsThree: moment(res.data.psBuilddate).format('YYYY-MM-DD')
            });
          }
        }
      })
    }).catch(err => {
      setDisabling(true)
      console.info('login err', err)
    })
  }

  const handleOk = () => {
    //提交采集器注册
    if (typeButton.current === 'pmuId' && okTextStr === t('All.Operation.submit')) {
      setSubPsInfo(Math.floor(Math.random() * 999999))
      setLoading(true)
      return;
    }
    registerPsForm.validateFields().then(values => {
      var params = { pmuSn: '', userId: '' }
      let psIdSn = values.registerPsForm['psIdSn']
      params['pmuSn'] = psIdSn;
      params['userId'] = props.userInfo['userId']
      if (typeButton.current === 'psId') {
        setLoading(true)
        addUserPsRole(params).then((res) => {
          switch (res.status) {
            case 200://根据电站ID添加关系
              window.location.reload() // 绑定成功刷新页面
              break;
            default:
              //setIsErrState(res.status)
              break;
          }
        })
      }

      if (typeButton.current === 'pmuId') {
        setPmuSn(psIdSn)
        if (okTextStr === t('All.Operation.next')) {//next
          setOkTextStr(t('All.Operation.submit'));//submit
          setNextNum(Math.floor(Math.random() * 999999))
        }
      }
    }).catch(err => {
      console.info('login err', err)
    })
  };

  const validatePsIdSn = async (value) => {
    const res = await service.get('service-psinfo/pmu/isExistPmuOrPs?ps_id_sn=' + value)
    return new Promise((resolve, reject) => {  // 返回一个promise
      resolve(res.status)
    })
    //   //   // isExistPmuOrPs(value).then(res => { // 这个是后台接口方法
    //   //   //   return res.status;
    //   //   //  // resolve(res.status)
    //   //   // }).catch(error => {
    //   //   //   return 0;
    //   //   // ///  reject(error)
    //   //   // })
    //   // })
  }

  return (
    <>
      <AddPsBtn>
        {/* <Button
          className='registerPs'
          type='text'
          icon={<PlusOutlined />}
          onClick={showModal}
        >
          <Typography.Text>{t('All.Operation.Add.addPlant')}</Typography.Text>
        </Button> */}

        <AddOperation
          btnText={t('All.Operation.Add.addPlant')}
          type='addPlant'
          onClick={showModal}
        />
      </AddPsBtn>
      <Modal
        className='ModalNormal ModalBuildPs DividerCenterSM'
        title={<Typography.Text>{t('All.FloatingBox.BoxTitle.addPlant')}</Typography.Text>}
        open={isOpen}
        onCancel={handleCancel}
        footer={[
          <Space>
            <Button key='back' onClick={handleBack} className={nextNum <= 0 ? 'isHide' : 'isShow'}>
              {t('All.Operation.back')}
            </Button>
            <Button key='submit' type='primary' disabled={disabling} loading={loading} onClick={handleOk}>
              {okTextStr}
            </Button>
          </Space>
        ]}
      >
        <RegisterWrapper className={nextNum <= 0 ? 'isShow' : 'isHide'} >
          <Form
            layout='vertical'
            hideRequiredMark
            form={registerPsForm}
            onFinish={handleIsExistPmuOrPs}
          >
            <Space direction='vertical' className='tip'>
              <Typography.Text>
                <Space size={0}>
                  <InfoCircleOutlined />
                  {t('Common.Header.RegisterPs.tip1')}
                </Space>
              </Typography.Text>
              <Typography.Text type='secondary'>{t('Common.Header.RegisterPs.tip2')}<span className='number-style-important'>15</span>{t('Common.Header.RegisterPs.tip2-1')}<span className='number-style-important'>12</span>{t('Common.Header.RegisterPs.tip2-2')}</Typography.Text>
            </Space>

            <Input.Group compact>
              <Form.Item
                getValueFromEvent={(event) => {
                  //正则匹配的是汉字和数字
                  // eslint-disable-next-line no-useless-escape
                  return event.target.value.replace(/[^\a-\z\A-\Z0-9\_]/g,'')
                }}
                name={['registerPsForm', 'psIdSn']}
                validateFirst={true}
                validateTrigger={['onSubmit']}
                rules={[
                  { required: true, message: t('All.Result.Error.FormRules.requiredItem') },
                  ({ getFieldValue }) => ({
                    validator: (rule, value, callback) => {
                      validatePsIdSn(value).then(status => {
                        switch (status) {
                          case 626:
                            callback(t('Common.Header.RegisterPs.626'));
                            break;
                          case 646:
                            callback(t('Common.Header.RegisterPs.646'));
                            break;
                          case 656:
                            callback(t('Common.Header.RegisterPs.656'));
                            break;
                          case 657:
                            callback(t('Common.Header.RegisterPs.657'));
                            break
                          case 200:
                            callback();
                            break
                          default:
                            callback();
                            break
                        }
                      })
                    }
                  }),
                ]}
              >
                <Input
                  placeholder={t('Common.Header.RegisterPs.tip3')}
                  autocomplete='off'
                  maxLength={15}
                  showCount
                />
              </Form.Item>
              <Button type='primary' htmlType='submit' onClick={handleIsExistPmuOrPs}>{t('All.Operation.verify')}</Button>
            </Input.Group>

            <Space direction='vertical' className='checked'  className={isErrState.current === 200 ? 'isShow DividerCenter' : 'isHide'}>
              <Space>
                <SuccessIcon />
                <Typography.Text type='success' className={typeButton.current === 'psId' ? 'isShow' : 'isHide'}>{t('Common.Header.RegisterPs.tip4')}</Typography.Text>
                <Typography.Text type='success' className={typeButton.current === 'pmuId' ? 'isShow' : 'isHide'}>{t('Common.Header.RegisterPs.tip5')}</Typography.Text>
              </Space>
              <Row gutter={24} className={typeButton.current === 'psId' ? 'isShow' : 'isHide'}>
                <Col xs={24} md={8}>
                  <Divider>
                    <Typography.Text>{t('Common.Header.RegisterPs.psName')}</Typography.Text>
                  </Divider>
                  <Typography.Text type='secondary'>{textInfo.paramsOne}</Typography.Text>
                </Col>
                <Col xs={24} md={8}>
                  <Divider>
                    <Typography.Text>{t('Common.Header.RegisterPs.psTypeName')}</Typography.Text>
                  </Divider>
                  <Typography.Text type='secondary'>{textInfo.paramsTwo}</Typography.Text>
                </Col>
                <Col xs={24} md={8}>
                  <Divider>
                    <Typography.Text>{t('Common.Header.RegisterPs.psBuilddate')}</Typography.Text>
                  </Divider>
                  <Typography.Text type='secondary'>{textInfo.paramsThree}</Typography.Text>
                </Col>
              </Row>
              <Row gutter={24} style={{"display":"masonry !important"}} className={typeButton.current === 'pmuId' ? 'Show' : 'isHide'}>
                <Col xs={24} md={8}>
                  <Divider>
                    <Typography.Text> {t('Common.Header.RegisterPs.model')}</Typography.Text>
                  </Divider>
                  <Typography.Text type='secondary'>{textInfo.paramsOne}</Typography.Text>
                </Col>
                <Col xs={24} md={8}>
                  <Divider>
                    <Typography.Text>{t('Common.Header.RegisterPs.brand')}</Typography.Text>
                  </Divider>
                  <Typography.Text type='secondary'>{textInfo.paramsTwo}</Typography.Text>
                </Col>
                <Col xs={24} md={8}>
                  <Divider>
                    <Typography.Text>{t('Common.Header.RegisterPs.bindDev')}</Typography.Text>
                  </Divider>
                  <Typography.Text type='secondary'>{textInfo.paramsThree}{t('Common.Header.RegisterPs.unit')}</Typography.Text>
                </Col>
              </Row>
            </Space>

          </Form>
        </RegisterWrapper>

        <AddPsInfo randomNum={nextNum} randomSubPs={subPsInfo} vailPsClick={handlePsVail} pmuSnSet={pmuSn} sunLoadSet={sunLoad} cancelSet={handleCancel} />

      </Modal>
      <Tour run={status} onSetStatus={onSetStatus} handleBack={handleBackAction} />
    </>
  )
})

const mapStateToProps = (state) => {
  return {
    psList: state.getIn(['header', 'psinfoList', 'list']),
    userInfo: state.getIn(['header', 'userinfoList', 'user'])
  }
}
const mapDippathToProps = (dispatch) => {
  return {
    handleLoad () { },
  }
}

export default connect(mapStateToProps, mapDippathToProps, null, { forwardRef: true })(RegisterPsInfo);