import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next' //语言包
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Select } from 'antd'
import 'antd/dist/antd.css'

import * as actionCreators from './store/actionCreators'

const { Option } = Select

const PsInfoList = (props) => {
  const { t } = useTranslation();
  const [defaultValuePsId, setDefaultValuePsId] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [showAllPs, setShowAllPs] = useState(false);
  const location = useLocation();

  const searchChange = (value) => {
    setSearchValue(value);
  }

  const selectHandel = (defaultValue, option) => {
    if (defaultValue === '') {
      setDefaultValuePsId('')
      props.handleChangeAllPs(true);
      window.sessionStorage.setItem('allPs',true);
    } else {
      props.handleChangeAllPs(false);
      window.sessionStorage.setItem('allPs',false);
      setDefaultValuePsId(defaultValue)
      setSearchValue('');
      window.sessionStorage.setItem('psId', defaultValue);
      props.handleChange(defaultValue, option)
    }
  }

  useEffect(() => {
    setSearchValue('');
    props.handleLoad();
  }, [props.isUpdate])//eslint-disable-line

  useEffect(() => {
    setShowAllPs(false)
    let psId = window.sessionStorage.getItem('psId');
    if (!props.isLoad) {
      let allPs = window.sessionStorage.getItem('allPs');
      if (psId === null && props.list.length > 0) {
        // eslint-disable-next-line no-const-assign
        psId = props.list[0].psId
      }
      if (location.pathname !== '/alarm') {
        setDefaultValuePsId(psId)
      } else {
        setShowAllPs(true)
        if(allPs==='true'){
          props.handleChangeAllPs(true);
          setDefaultValuePsId('')
          psId = ''
        }else{
          props.handleChangeAllPs(false);
        }
        if (psId !== undefined && psId !== null && psId !== '') {
          setDefaultValuePsId(psId)
        }
      }
    }
  }, [location.pathname, props.isLoad])//eslint-disable-line

  return (
    <>
      <Select
        size='large'
        optionLabelProp='key'
        showArrow={false}
        showSearch
        placeholder={t('All.Parameter.InForm.Lable.inputSearchPs')}
        className={props.className}
        onFocus={props.handleInputFocus}
        onChange={selectHandel}
        onSearch={searchChange}
        key={defaultValuePsId}
        defaultValue={defaultValuePsId}
        value={defaultValuePsId}
        filterOption={(input, option) =>
          (option?.key ?? '').toLowerCase().includes(input.toLowerCase())
        }
      >
        <Option key={t('All.Parameter.InForm.Lable.selectAllPs')} value={''} option={{ psId: '', psName: t('All.Parameter.InForm.Lable.selectAllPs') }} className={showAllPs ? 'isShow' : 'isHide'}>{t('All.Parameter.InForm.Lable.selectAllPs')}</Option>
        {
          props.list.map((option, index) => (
            <Option key={option.psName} value={option.psId} option={option}>
              <LightHeightOption text={`${option.psName}`} filterTxt={searchValue}></LightHeightOption>
            </Option>
          ))
        }
      </Select>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoad: state.getIn(['header', 'psinfoList', 'isLoad']),
    allPs: state.getIn(['header', 'psinfoList', 'allPs']),
    defaultValue: state.getIn(['header', 'psinfoList', 'defaultValue']),
    list: state.getIn(['header', 'psinfoList', 'list']),
    psInfo: state.getIn(['header', 'psinfoList', 'psInfo']),
    isUpdate: state.getIn(['psInfo', 'isUpdate']),
  }
}
const mapDippathToProps = (dispatch) => {
  return {
    handleLoad () {
      dispatch(actionCreators.psInfoListLoad(true))
      dispatch(actionCreators.getPsInfoList());
    },
    handleChange (defaultValue, option) {
      dispatch(actionCreators.setPsinfo(option.option));
      dispatch(actionCreators.selectPs(defaultValue));
    },
    handleChangeAllPs (allPs) {
      dispatch(actionCreators.setAllPs(allPs));
    }
  }
}

export default connect(mapStateToProps, mapDippathToProps)(PsInfoList);

const LightHeightOption = (props) => {
  const {
    filterTxt, text,
  } = props
  const heightLightTxt = (txt, heightTxt) => {
    if (heightTxt === '') {
      return txt
    }
    // 前面filterOption 不区分大小写，这里用i
    const regexp = new RegExp(heightTxt, 'gi')
    return txt.replace(regexp, `<span style='color:#ff9c00'>${heightTxt}</span>`)
  }
  return (
    <div ref={(nodeElement) => {
      if (nodeElement) {
        nodeElement.innerHTML = heightLightTxt(text, filterTxt)
      }
    }}
    />
  )
}