import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //语言包
import { connect } from 'react-redux';
import cookie from 'react-cookies';

import { Col, Row, Form, Input, Divider, Select, Typography, DatePicker, Button, Modal, ConfigProvider } from 'antd';
import { RegisterPsInfoWrapper } from './style';

import { Success, Error } from '../../../../common/notification';
import { WithRequiredMark } from '../../../../common/textOrnament';

import { getCountrys, getProvinces, getCitys, getPsTypes, getTimezones, registerByPmu } from '../../../../request/api';
import { valiIsPsName } from '../../../../utils/validator';
import moment from 'moment';

import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import jaJP from 'antd/es/locale/ja_JP';

const RegisterPsInfo = props => {
    const { t } = useTranslation();
    var userId = cookie.load('userId'); // window.sessionStorage.getItem('userId')
    const [psInfoForm] = Form.useForm();
    const [countryLists, setCountryLists] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [psTypeList, setPsTypeList] = useState([]);
    const [selCity, setSelCity] = useState(null);
    const [psBuildDate, setPsBuildDate] = useState(moment().format('YYYY-MM-DD'));
    let regSub = useRef();
    const psParams = useRef({});

    const [locale, setLocal] = useState(null);
    //切换语言
    useEffect(() => {
        switch (props.language) {
            case 'zh-cn':
                setLocal(zhCN);
                break;
            case 'ja-jp':
                setLocal(jaJP);
                break;
            case 'en-us':
                setLocal(enUS);
                break;
            default:
                setLocal(zhCN);
                break;
        }
    }, [props.language]);

    useEffect(() => {
        if (props.randomNum > 0 && props.sunLoadSet) {
            initPs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.randomNum, props.sunLoadSet]);

    useEffect(() => {
        if (props.randomSubPs !== 0) {
            regSub.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.randomSubPs]);

    //重置表单信息
    const initPs = () => {
        psInfoForm.resetFields();
        handleLoadCountryList();
        handleLoadPsTypeList();
        handleLoadTimeZoneList();
        psInfoForm.setFieldsValue({
            psInfoForm: {
                psBuilddate: moment(),
            },
        });
    };

    //注册采集器电站信息
    const handleSubRegPs = (values: any) => {
        psInfoForm
            .validateFields()
            .then(values => {
                psParams.current = {
                    pmuSn: props.pmuSnSet,
                    psBuilddate: psBuildDate + ' 00:00:00',
                    provinceId: values.psInfoForm['provinceId'],
                    cityId: values.psInfoForm['cityId'],
                    psTypeId: values.psInfoForm['psTypeId'],
                    psName: values.psInfoForm['psName'],
                    longitude: selCity.longitude,
                    latitude: selCity.latitude,
                    psCapacity: 0,
                    psAddress: values.psInfoForm['psAddress'],
                    timezoneId: values.psInfoForm['psTimezone'],
                };
                registerByPmu(userId, psParams.current).then(res => {
                    if (res.status === 200) {
                        props.cancelSet();
                        var sucModal = Modal.success();
                        sucModal.update({
                            icon: '',
                            content: (
                                <Success
                                    messageTitle={t('Alarm.EmailSet.Notification.Save.Success.Title')}
                                    messageSubTitle={''}
                                    messageBtn={t('Alarm.EmailSet.Notification.Save.Success.btn')}
                                    handleModel={sucModal}
                                />
                            ),
                            okButtonProps: { style: { display: 'none' } }, // 隐藏确认按钮
                            cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
                        });
                        window.location.reload(); // 绑定成功刷新页面
                    } else {
                        var errModal = Modal.error();
                        errModal.update({
                            icon: '',
                            content: (
                                <Error
                                    messageTitle={t('Alarm.EmailSet.Notification.Save.Error.Title')}
                                    messageSubTitle={t('Alarm.EmailSet.Notification.Save.Error.SubTitle')}
                                    messageBtn={t('Alarm.EmailSet.Notification.Save.Error.btn')}
                                    handleModel={errModal}
                                />
                            ),
                            okButtonProps: { style: { display: 'none' } }, // 隐藏确认按钮
                            cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
                        });
                    }
                    //console.info('regPs', res)
                });
                props.vailPsClick(false);
            })
            .catch(err => {
                props.vailPsClick(false);
                console.info('regPs err', err);
            });
    };
    const handleLoadCountryList = () => {
        getCountrys().then(res => {
            if (res.success) {
                setCountryLists(res.data);
            }
        });
    };

    //选择国家列表
    const handleChangeCountry = value => {
        psInfoForm.setFieldsValue({
            psInfoForm: {
                provinceId: '',
            },
        });
        setSelCity(null);
        getProvinces(value).then(res => {
            if (res.success) {
                setProvinceList(res.data);
            }
        });
        timeZoneList.forEach((item, index) => {
            if (item.countryId === value) {
                psInfoForm.setFieldsValue({
                    psInfoForm: {
                        psTimezone: item.value,
                    },
                });
            }
        });
    };

    //获取城市信息
    const handleChangeProvinces = value => {
        psInfoForm.setFieldsValue({
            psInfoForm: {
                cityId: '',
            },
        });
        setSelCity(null);
        getCitys(value).then(res => {
            if (res.success) {
                setCityList(res.data);
            }
        });
    };

    //切换城市列表
    const handleChangeCitys = value => {
        if (cityList.length > 0) {
            setSelCity(null);
            cityList.forEach((item, index) => {
                if (item.cityId === value) {
                    setSelCity(item);
                }
            });
        }
    };

    //获取电站类型
    const handleLoadPsTypeList = () => {
        getPsTypes().then(res => {
            if (res.success) {
                res.data.forEach((item, index) => {
                    item.psTypeName = getPsType(item.psTypeId);
                });
                setPsTypeList(res.data);
            }
        });
    };

    const handleLoadTimeZoneList = () => {
        getTimezones().then(res => {
            if (res.success) {
                var arr = [];
                res.data.forEach((item, index) => {
                    var obj = {
                        value: item.timezoneId,
                        label: item.timezoneName,
                        countryId: item.countryId,
                    };
                    arr.push(obj);
                });
                setTimeZoneList(arr);
            }
        });
    };

    const pickerOnChange = (date, dateString) => {
        setPsBuildDate(dateString);
    };

    // const Date = (current) => {
    //   // Can not select days before today and today
    //   let monentDate = psBuilddate.replace('-', '').replace('-', '')
    //   return current && (current < moment(monentDate).endOf('day') || current > moment().endOf('day'));
    // };

    const getPsType = typeId => {
        var psName = '';
        switch (typeId) {
            case 1:
                psName = t('home.PsType.TypeSelf');
                break;
            case 2:
                psName = t('home.PsType.TypeLoad');
                break;
            case 3:
                psName = t('home.PsType.TypePeak');
                break;
            case 4:
                psName = t('home.PsType.TypePpv'); //系统侧
                break;
            case 5:
                psName = t('home.PsType.TypeGrid'); //PPA
                break;
            case 11:
                psName = t('home.PsType.TypeSelf2');
                break;
            case 13:
                psName = t('home.PsType.TypeFip'); //PPA
                break;
            case 14:
                psName = t('home.PsType.HighPressure'); //"高压蓄电所"; //
                break;
            default:
                break;
        }
        return psName;
    };

    return (
        <>
            <RegisterPsInfoWrapper className={props.randomNum > 0 ? 'isShow' : 'isHide'}>
                <Form className="AddForm" layout="vertical" hideRequiredMark form={psInfoForm} onFinish={handleSubRegPs}>
                    <Row gutter={16}>
                        <Col xs={24} xl={12}>
                            <Divider className="DividerWarning" orientation="left" orientationMargin="0">
                                <WithRequiredMark carrierContent={t('Common.Header.RegisterPs.psName')} />
                            </Divider>
                            <Form.Item
                                name={['psInfoForm', 'psName']}
                                rules={[
                                    { required: true, message: t('All.Result.Error.FormRules.requiredItem'), whitespace: true },
                                    { validator: valiIsPsName, message: t('Common.Header.RegisterPs.psName') },
                                ]}
                                validateFirst={true}
                                validateTrigger={['onBlur']}
                                hasFeedback>
                                <Input placeholder={t('Common.Header.RegisterPs.psName')} autocomplete="off" maxLength={15} showCount />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={11} xl={6}>
                            <Divider className="DividerWarning" orientation="left" orientationMargin="0">
                                <WithRequiredMark carrierContent={t('PsInfo.Form.psTypeId.label')} />
                            </Divider>
                            <Form.Item name={['psInfoForm', 'psTypeId']} rules={[{ required: true, message: t('All.Result.Error.FormRules.requiredItem') }]}>
                                <Select placeholder={t('Common.Header.RegisterPs.choosePsType')} validateFirst={true} validateTrigger={['onChange']}>
                                    {psTypeList.map((item, index) => {
                                        return (
                                            <Select.Option key={item.psTypeId} value={item.psTypeId}>
                                                {getPsType(item.psTypeId)}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={11} xl={6}>
                            <Divider className="DividerWarning" orientation="left" orientationMargin="0">
                                <WithRequiredMark carrierContent={t('PsInfo.Form.psBuilddate.label')} />
                            </Divider>
                            <Form.Item
                                name={['psInfoForm', 'psBuilddate']}
                                rules={[{ required: true, message: t('All.Result.Error.FormRules.requiredItem') }]}
                                validateFirst={true}
                                validateTrigger={['onChange']}
                                hasFeedback>
                                <ConfigProvider locale={locale}>
                                    <DatePicker onChange={pickerOnChange} allowClear={false} picker={'date'} defaultValue={moment()} />
                                </ConfigProvider>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} md={6} lg={6}>
                            <Divider className="DividerWarning" orientation="left" orientationMargin="0">
                                <WithRequiredMark carrierContent={t('PsInfo.Form.countryId.label')} />
                            </Divider>
                            <Form.Item
                                name={['psInfoForm', 'countryId']}
                                validateFirst={true}
                                validateTrigger={['onChange']}
                                rules={[{ required: true, message: t('All.Result.Error.FormRules.requiredItem') }]}>
                                <Select placeholder={t('PsInfo.Form.countryId.placeholder')} onChange={handleChangeCountry}>
                                    {
                                        // eslint-disable-next-line array-callback-return
                                        countryLists.map((item, index) => {
                                            if (item.countryId === '2112089P2Z73H46W') {
                                                return (
                                                    <Select.Option key={item.countryId} value={item.countryId}>
                                                        {item.countryName}
                                                    </Select.Option>
                                                );
                                            }
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={6} lg={6}>
                            <Divider className="DividerWarning" orientation="left" orientationMargin="0">
                                <WithRequiredMark carrierContent={t('PsInfo.Form.provinceId.label')} />
                            </Divider>
                            <Form.Item
                                name={['psInfoForm', 'provinceId']}
                                validateFirst={true}
                                validateTrigger={['onChange']}
                                rules={[{ required: true, message: t('All.Result.Error.FormRules.requiredItem') }]}>
                                <Select placeholder={t('PsInfo.Form.provinceId.placeholder')} onChange={handleChangeProvinces}>
                                    {provinceList.map((item, index) => {
                                        return (
                                            <Select.Option key={item.provinceId} value={item.provinceId}>
                                                {item.provinceName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={6} lg={6}>
                            <Divider className="DividerWarning" orientation="left" orientationMargin="0">
                                <WithRequiredMark carrierContent={t('PsInfo.Form.cityId.label')} />
                            </Divider>
                            <Form.Item
                                validateFirst={true}
                                validateTrigger={['onChange']}
                                labelAlign={'right'}
                                name={['psInfoForm', 'cityId']}
                                rules={[{ required: true, message: t('All.Result.Error.FormRules.requiredItem') }]}>
                                <Select placeholder={t('PsInfo.Form.cityId.placeholder')} onChange={handleChangeCitys}>
                                    {cityList.map((item, index) => {
                                        return (
                                            <Select.Option key={item.cityId} value={item.cityId}>
                                                {item.cityName}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={6} lg={6}>
                            <Divider className="DividerWarning" orientation="left" orientationMargin="0">
                                <WithRequiredMark carrierContent={t('PsInfo.Form.psTimeZone.label')} />
                            </Divider>
                            <Form.Item
                                validateFirst={true}
                                validateTrigger={['onChange']}
                                labelAlign={'right'}
                                name={['psInfoForm', 'psTimezone']}
                                rules={[{ required: true, message: t('All.Result.Error.FormRules.requiredItem') }]}>
                                <Select placeholder={t('Common.Header.RegisterPs.chooseTimeZone')} options={timeZoneList} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Divider className="DividerWarning" orientation="left" orientationMargin="0">
                                <Typography.Text>{t('PsInfo.Form.psAddress.label')}</Typography.Text>
                            </Divider>
                            <Form.Item name={['psInfoForm', 'psAddress']} validateFirst={true} validateTrigger={['onBlur']} hasFeedback>
                                <Input placeholder={t('PsInfo.Form.psAddress.placeholder')} autocomplete="off" maxLength={50} showCount />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Button ref={regSub} className="isHide" onClick={handleSubRegPs}></Button>
                </Form>
            </RegisterPsInfoWrapper>
        </>
    );
};

const mapStateToProps = state => {
    return {
        language: state.getIn(['header', 'global', 'language']),
    };
};
const mapDippathToProps = dispatch => {
    return {
        handleLoad() {},
    };
};

export default connect(mapStateToProps, mapDippathToProps)(RegisterPsInfo);
