import * as constants from './actionTypes';
import { fromJS } from 'immutable';
const defaultState = fromJS({
  pmuinfo: {},
})

const ReducrePmu = (state = defaultState, action) => {
  switch (action.type) {
    case constants.SEL_PMU:
      return state.set('pmuinfo', action.data)
    default:
      return state;
  }
}
export default ReducrePmu